import React from 'react'
import "./Patent.css"

export default function Patent() {
  return (
    <div className='text'>
       {/* <iframe className='img2' src="https://i.ibb.co/ZhBVjCS/v01.jpg" title="PDF"></iframe> */}
      <h1 className='mainhead_patent'>
        LASTEST PATENT RELEASE
      </h1>
      <p className='header_patent_1'>
      The company has developed a sophisticated AI patent for financial/stock market regulators, leveraging advanced mathematics and cognitive brain technology to detect and prevent insider trading and other illicit activities in the stock market. 
      The comprehensive White Paper published here sheds light on the AI patent's cutting-edge architecture.
      </p>
      
     
      <div className='box_patent'>
        <h1 className='header_patent_2'>Advanced Artificial Intelligence for Stock Market Regulator: A Breakthrough in Detecting Insider Trading/Market Frauds via a new Cognition-Induced AI: 
            </h1>
        <div style={{textAlign:"center"}}>
        <h5><b>Ramesh Babu Thimmaraya</b></h5>
        <p>
          Founder and Chief Scientist<br/>
           Cnyda Dynamics Inc.<br/>
           </p>
       
        </div>
        <div className='wall_patent'>
        <div>
            <h5 className='patent_one'><b>Ankita Vinod Sharma</b></h5>
            <p className='patent_two'>
            Director and Head of Research & Development<br/>
            AI Research Division, Cnyda Dynamics Inc.<br/>
            </p>
        </div>
        <div>
        <h5 className='save_patent'><b>Mouli Jaya. T</b></h5>
        <p className='save_1_patent'>
        Jr. Scientist and Technologist<br/>
        AI Research Division, Cnyda Dynamics Inc.<br/>
        </p> 
        <p></p>
        </div>
        </div>
        <div>
          <h3 className='h3_patent'>Executive Summary:</h3>
          <p className='head_patent'>•	There is a pressing concern among governments and regulatory agencies globally regarding the alarming rise in digital frauds, 
            particularly in the financial/stock market sector, such as insider trading and pump-and-dump schemes and many more, in this rapidly evolving digital technology era</p>
          <p className='head_patent'>•	The limited availability of evidence has made it challenging for regulatory authorities to effectively address fraudulent activities in the market place, hence they should leverage cutting-edge technologies like AI to safeguard investors from these types of frauds</p>
          <p className='head_patent'>•	While generative AI has garnered significant hype and has many promising use cases like language translation, image generation, customer service agent, information retrieval and code generation but it struggles with tasks that demand sophisticated thinking abilities and is inadequate for tasks that require exceptionally high reliability/accuracy, such as those with six-sigma standards. The Cognitive-AI model developed in this paper demonstrates exceptional proficiency in tasks that necessitate advanced human-like thinking and boasts six-sigma reliability, 
            accompanied by exponential gains in productivity</p>
          <p className='head_patent'>•	An in-depth evaluation of the performance of multiple generative AI models, 
            such as GPT4, has been undertaken in comparison to the new Cognitive-AI, 
            in all the regulatory fraud detection case studies we have analyzed, the Cognitive-AI model not only surpassed the performance of conventional generative AI models like GPT4 but also provided a wealth of scientific evidence to substantiate its findings</p>
          <p className='head_patent'>•	The innovative Cognitive-AI patent showcased in this paper offers a highly adaptable framework, enabling it to embody diverse avatars and roles with varying degrees of complexity by carefully crafting its cognitive and empirical components. We are currently developing advanced Avatars like the 'Expert Doctor' that can think and act like expert medical professionals in real-time, providing accurate diagnoses and treatments for patients and also avatars like 'Proficient Lawyer' variant, 
            which can craft compelling legal arguments and offer insightful counsel for clients.</p>
        </div>
        <div>
          <h3 className='h3_patent'>Introduction:</h3>
          <p className='head_patent'>
          In the current digital age, the advent of technology has revolutionized the way we access 
          information and connect with others. The process of accessing individuals or information 
          has become incredibly efficient, allowing us to obtain what we need with ease. 
          The availability of such technology has created a wealth of opportunities for economic 
          growth and innovation. In the fast-paced world of the financial/stock markets, 
          technology has brought about both benefits and drawbacks. While it has made it easier 
          for investors to access information and enabling quick transactions, it has also 
          created new avenues for manipulation and deception. For example, social media, 
          in particular, has made it easier for individuals to reach a large audience with minimal 
          effort thereby increasing the risk of <b>Pump-and-Dump</b> schemes and other forms of market 
          manipulation. The ease of transferring and receiving confidential information has made 
          it easier for individuals to engage in illegal activities, such as <b>Insider Trading</b>, 
          by sharing confidential information about a particular stock to gain an unfair advantage 
          in the market. 
          </p>
          <p className='head_patent'>
          The ease of committing fraud using technology has made it increasingly difficult for regulators to gather scientific evidence to support their investigations and prosecutions, Most Americans Today Believe the Stock Market Is Rigged [1]. The present patent/paper aims to address this challenge by developing an AI-powered toolkit that can effectively monitor and detect stock market frauds, providing regulatory agencies with a powerful weapon in their fight against financial crime. 
          </p>
          <p className='head_patent'>
          This paper is structured into sections that are presented in a logical and orderly fashion namely, Need for new AI technology, Architecture and neural schema of the new Cognitive-AI patent, Detection of Insider Trading/Market frauds by new Cognitive-AI model, Performance and Comparative Analysis of Cognitive-AI vis-a-vis GPT4 model and Final output of the detection model.
          </p>
          <h3 className='h3_patent'>Need for New AI Technology:</h3>
          <p className='head_patent'>Even though there are different types of AI technologies evolving from decades, the current wave of generative AI has indeed captured the attention of the world and has brought about a lot of fame and recognition to the individuals and companies involved in its development and application, most of its fame is attributed to the Neural Machine Translation By Jointly Learning To Align And Translate [2] and Attention is all you need [3]. However, there is still a lot of journey left to explore in this direction. The generative AI model, such as ChatGPT/GPT4, has demonstrated remarkable language capabilities, including the ability to answer questions with good grammar and retrieve or generate information quickly. These capabilities can be used quite effectively for search engine models and information retrieval applications, customer service, language translation, and like content creation. </p>
          <p className='head_patent'>
          However, in tasks that require deep expertise or critical thinking, human experts are still far more effective and efficient than AI models because these models are typically trained on large datasets and rely on statistical patterns to generate responses, rather than truly understanding the meaning or context of the input. For example, in fields such as law, medicine, personal advice and regulatory fraud detection tasks, human experts are able to provide nuanced and case specific advice that takes into account the circumstances and needs of each individual case. The above view is in concurrence with Sam Altman's argument: “the generative AI models like ChatGPT/GPT4 tend to produce answers that are consistent with the majority view or the median-man, rather than providing expert-level insights or novel solutions”.
          </p>
          <p className='head_patent'>
          The above  limitation of generative AI in solving serious real world problems has motivated us do research on alternate AI technologies which can think and process information like an expert and it should solve real world problems like detection of frauds for the market regulators in this particular paper.  However, the new AI technology is very generic and can be trained to take any avatar, given the cognition of a doctor it takes “Doctor Role” or given the cognition of lawyer it take “Lawyer avatar” and so on, because of this unique aspect, the new patent has been named Cognitive-AI, emphasizing its ability to think and process information like a human. 
          </p>
          <p className='head_patent'>
          An experimental study was performed on the Cognitive-AI taking the role of an “Economist” to understand the world economic dynamics, some of its predictions for the next decade are published in “Dynamics of Financial Stress and Economic Performance: Insights and Analysis from the World Economy” [4], such as, a slow-down in the world economy especially in developed countries, unemployment of 40% in educated youth, problems with Chinese real-estate investments, structural breaks to the globalization process, fate and failure of crypto currency  and many more.
          </p>
          <p className='head_patent'>
          The growth of AI technologies is nothing short of explosive, with new breakthroughs and innovations being made at an exponential rate. However, the fundamental mathematical equations that underpin these AI developments have remained relatively unchanged for several decades; thus highlighting the need for new breakthroughs and innovations in the underlying mathematical structures. This Cognitive-AI patent discussed in this paper is an attempt to break    new ground in the area of mathematical modeling of the human brain, offering a novel approach to understanding the brains intricate structure.
          </p>
        </div>
        <div>
          <h3 className='h3_patent'>Architecture of the new Cognitive-AI: Neural Schema based on Philosophy of Mind </h3>
          <p className='head_patent'>The new AI model is developed based on the Theory of Mind, many intellectuals have proposed various hypotheses regarding the functioning of the human mind. The theory of mind presented in this paper is founded on the principles of borrowed epistemology*, offering a fresh perspective on the relationship between cognition and consciousness. Our theory proposes that the human brain is composed of two distinct structures, one for logical reasoning and the other for subjective experience. The integration of these two brain structures culminates in the development of a sophisticated cognitive entity known as Cognitive-AI.</p>
          <p className='head_patent'>This patent was inspired by the philosophical ideas of Rene Descartes and Leibniz, specifically their concept of a rational brain structure, and incorporated this inspiration into the development of the patent to the extent of 20%. Similarly, this patent was influenced by the ideas of John Locke and David Hume, specifically their concept of the empirical brain structure, and integrated this inspiration into the patent to the extent of 25%.</p>
          <img className='img5_1' src='https://i.ibb.co/2cwYq3H/ai-img.png'/>
          <p className='head3_patent'>Figure-1: Architecture of the new Cognitive-AI technology</p>
          <p className='head_patent'>The rational brain is a distinct cognitive structure within the AI avatar/patent, providing logical and rational connections to the empirical brain, thereby facilitating the thinking process. The training process involves the integration of multiple empirical brains; each contributing to the development of the AI avatar's cognitive abilities, following the training process, the multiple empirical brains are synthesized into a single, giant empirical brain. The integration of multiple empirical brains into a single, giant empirical brain enhances the AI avatar's cognitive abilities by leveraging the diverse experiences and knowledge of a large group of individuals.</p>
          <p className='head_patent'>The schematic of the empirical brain synthesisation process in Figure-1 offers a comprehensive overview of the various cognitive components and their interactions, providing a blueprint for the development of advanced AI systems. Finally the induction of the giant empirical brain into the unique rational brain enables this new AI technology to possess a comprehensive range of cognitive abilities, making it a truly advanced and human-like intelligence. This new AI technology is seamlessly integrated with the generative AI model through a parallel loop modeling technique, allowing for the creation of both thinking as well as language capabilities.</p>
        </div>
        <div>
          <h3 className='h3_patent'>Detection of Insider Trading/Market frauds by new Cognitive-AI model:</h3>
          <p className='head_patent'>The state-of-the-art detection model is made up of two distinct parts: the AI patent, which is the intellectual property of the company, and confidential regulatory data. To maintain the privacy and security of this information, the insider trading/fraud detection model is carried out on two separate servers, ensuring the highest level of security and discretion. A detailed schematic of the insider trading/fraud detection model is outlined in Figure-2.</p>
          <img className='img5_2' src='https://i.ibb.co/X31Gqvn/ai-regulatory.png'/>
          <p className='head3_patent'>Figure-2: Insider trading/fraud detection model using Cognitive-AI patent</p>
          <p className='head_patent'>The AI patent is run on the first server using the following inputs: price data of a particular stock, the amount of money transacted in that stock, company-specific information released by the company, industry-specific information, the company's social media presence including rumors, and publicly available news about the company. The inputs given to this AI  
            model is analyzed and processed to generate two key outputs: 
            suspicious zones of trading and a suspicion score for each zone.</p>
          <p className='head_patent'>
          The First output of the AI model is the suspicious zone of trading, which is a time period when the company's information dynamics have been compromised, and some traders have made decisions based on confidential information, as estimated from price dynamics and other input data. The second output of the AI model is a suspicion score attached to each suspicious zone of trading, based on the amount of money transacted and the degree of price manipulation. The AI model output offers the regulator a targeted approach, allowing them to concentrate their resources on specific stocks and time periods based on the level of suspicion.  
          </p>
          <p className='head_patent'>
          The second server a.k.a regulatory server runs the final part of the detection model. The outputs from the previous step (i.e., the outcomes from the AI model) are given as input to the regulatory server along with the micro-market data from the exchange as third input. The regulatory server model will prioritize the suspicious zones of trading based on the suspicion score, focusing on specific micro-market data for each trading client to identify any potential suspicious patterns in that zone. 
          </p>
          <p className='head_patent'>
          Finally, the regulatory server model delivers two critical outputs: the suspicious client ID, which pinpoints the client responsible for the suspicious activity, and the burden of evidence score, which assesses the severity of the evidence against that client. The suspicious client IDs will be ranked based on the burden of evidence score, with higher scores indicating a greater weight of evidence. The regulatory agency will conduct a thorough review of traders with high burden of evidence scores, and those found to be non-compliant will be subject to further investigation and monitoring to protect the market and investors.
          </p>
        </div>
        <div>
          <h3 className='h3_patent'>Performance and Comparative Analysis of Cognitive-AI vis-a-vis GPT4 model:</h3>
          <p className='head_patent'>The performance of the Cognitive-AI in comparison to the GPT4 model is showcased in this section with a selection of samples demonstrating their capabilities. In all the regulatory case studies we examined, the Cognitive-AI model demonstrated superior performance compared to the GPT4 model in the regulatory fraud detection tasks, which require critical thinking and data manipulation*. </p>
          <h3 className='h3_patent'>Case Study 1:</h3>
          <p className='head_patent'>Question/Prompt: “Do you have any suspicion of insider trading happening in Raymond stock in the year 2023?”</p>
          <p className='head_patent'>GPT4 answer: Without any concrete proof, I cannot say whether there was any insider trading happening in Raymond’s stock in 2023 or not.</p>
          <p className='head_patent'><b>Cognitive-AI answer:</b></p>
          <img className='img5_3' src='https://i.ibb.co/KWQ2tBh/img1-patent.png'/>
          <p className='head3_patent'>Table-1: Cognitive AI model output for Raymond stock</p>
          <h3 className='h3_patent' >Case Study 2:</h3>
          <p className='head_patent'>Question/Prompt: “Do you have any suspicion of insider trading happening in UCO BANK stock in the year 2022?”</p>
          <p className='head_patent'>GPT4 answer: : Without any concrete proof, I cannot say whether there was any insider trading happening in UCO Bank’s stock in 2022 or not.</p>
          <p className='head_patent'><b>Cognitive-AI answer:</b></p>
          <img className='img5_4' src='https://i.ibb.co/6wG7Hq8/img2-patent.png'/>
          <p className='head3_patent'>Table-2: Cognitive AI model output for UCO bank stock</p>
          <h3 className='h3_patent' >Case Study 3:</h3>
          <h3 className='h3_patent'>Final output of the detection model: A sample template*</h3>
          <p className='head_patent'><b>Cognitive-AI answer:</b></p>
          <img className='img5_5' src='https://i.ibb.co/m86ggmx/img3-patent.png'/>
          <p className='head3_patent'>Table-3: Regulatory model sample output</p>
          <p className='head_patent'>According to the output in Table-3, a trader with masked ID 291B528 has been suspected to have engaged in insider trading in UCO Bank's stock in 2022, with a medium burden of evidence score. It is thus suggested that this ID to be further monitored. Similarly, in the year 2023, a trader with masked ID 834K907 has been suspected to have engaged in insider trading in Raymond's stock with a high burden of evidence score. The agency may proceed with further investigation and potential prosecution for this ID, as the burden of evidence score indicates a strong likelihood of insider trading.</p>

        </div>
        <div>
          <h3 className='h3_patent'>References:</h3>
          <p>1.	Vaughan Liam,” Most Americans Today Believe the Stock Market Is Rigged, and They’re Right”, September 29, 2021, Bloomberg.com</p>
          <p>2.	D Bahdanau, K Cho, Y Bengio, (2015) Neural Machine Translation By Jointly Learning To Align And Translate, https://arxiv.org/abs/1409.0473</p>
          <p>3.	Vaswani, Ashish & Shazeer, Noam & Parmar, Niki & Uszkoreit, Jakob & Jones, Llion & Gomez, Aidan & Kaiser, Lukasz & Polosukhin, Illia, “Attention is all you need”, 2017</p>
          <p>4.	Ramesh B. Thimmaraya, M.Venkateshwarlu, (2018),” Dynamics of Financial Stress and Economic Performance: Insights and Analysis from the World Economy”.</p>
        </div>
      </div>
    </div>
  )
}
