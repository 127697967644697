import React from 'react';
import {  Link } from "react-router-dom";
import "./Navbar1.css";


const Navbar= () =>{
  return (
  <nav className='topnav'>
    <ul className='nav'>  
    <li>
      <Link  to="/" ><b>Home</b></Link>
    </li>
    <li>
      <Link to="/Research"><b>Research</b></Link>
    </li>
    <li>
      <Link to="/Products"><b>Products</b></Link>
    </li>
    <li>
      <Link to="/Patents"><b>Patents</b></Link>
    </li>
    {/* <li>
      <Link to="/About">About</Link>
    </li> */}
    </ul>
  </nav>
  );
}
export default Navbar;