
import React from 'react'
import "./evLine1.css";

export default function EvLine() {
  return (
    
    <div className='title'>
      <div className='wall'>
        <div>
           <h1 className='con'>CNYDA DYNAMICS</h1>  
        </div>
        <div>
           <h1 className='num'>COGNITIVE BRAIN AI</h1> 
        </div>
        </div>
        <div className='vv1'>
          <img className='nn1' src="https://i.ibb.co/FX3jkDj/Logo-new-1-removebg.png"/>
          <img className='bb1' src="https://i.ibb.co/r0RkvZn/m2.png"/>
        </div>
      </div>
  )
}

