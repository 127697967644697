import React from 'react'
import "./footer.css"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEnvelope} from '@fortawesome/free-solid-svg-icons'
// import { faFacebookF,faInstagram } from '@fortawesome/free-brands-svg-icons'



export default function Footer() {
  return (
    <div className='box'>
      <div className="footer">
            <h3 className='header_4'>CNYDA DYNAMICS INC.</h3>
            <p className='ptag'>Landmark: Two hours from Bangalore International airport via. KGF</p>
            <p className='cright'>Copyright &copy;2024 Cnyda Dynamics Inc.<br/>
            Web Designed & Managed By:<span className='spantag'>T. Mouli Jaya</span><br/>
            </p>
            </div>
            </div>
  )
}