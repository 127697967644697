import React from 'react'
import "./Research.css";


export default function Research() {
  return (
    <div>
      <h1 className='mainhead_2'>
      Cnyda Dynamics Research & Development
      </h1>
      <p className='h4tag_2'>
        The company primarily focuses on conducting research in a wide range of fields, 
        including cutting-edge technologies, advanced applied mathematics, 
        international/macro economics, 
        and cognitive behavioral sciences with the aim of expanding human knowledge and driving positive change. 
        Currently, the company is devoting its efforts to designing novel AI architectures that deviate from the conventional generative AI approaches.
        While generative AI has garnered significant hype and has many promising use cases like language translation, image generation, customer service agent, 
        information retrieval and code generation but it struggles with tasks that demand sophisticated thinking abilities and is inadequate for tasks that require exceptionally high reliability/accuracy. 
        It is well known that generative AI models are notorious liars, they miserably fail in tasks requiring six-sigma standards (around 3 errors in one million decisions).
        The organization is tirelessly working towards developing AI models of the highest caliber, boasting an unprecedented six-sigma reliability that guarantees absolute truthfulness. 
      </p>
      <h2 className='name_r_1'>
        The on-going research activities are:
      </h2>
      <p className='h4tag_2'>
        
        Cognitive Brain AI models developed by the company 
        demonstrates exceptional proficiency in tasks that necessitate advanced human-like thinking and boasts high reliability, accompanied by exponential gains in productivity.
      </p>
      <h2 className='name_2'>
        Rational Brain Research:
      </h2>
      <div >
        <img className='image_2' src="https://i.ibb.co/dW9RT1C/brain-2.jpg" alt='background'/>
    </div>
      <p className='h4tag_2'>
      Exploring the mathematical foundations of rational brain structure through the lens of the Philosophy of mind. 
      This research is inspired by the philosophical ideas of Rene Descartes, specifically his concept of a rational brain structure, 
      the rational brain is a distinct cognitive structure within the AI avatar, providing logical and rational connections to the empirical brain, 
      thereby facilitating the thinking process. The training process involves the integration of multiple empirical brains; each contributing to the development of the AI avatar's cognitive abilities.
      </p>
      <h2 className='name_2'>
        Cognitive Brain Research:
      </h2>
      <div >
        <img className='image_2' src="https://i.ibb.co/XFmfh1b/brain-3.jpg" alt='background'/>
    </div>
      <p className='h4tag_2'>
      Currently studying cognitive-behavioral sciences to gain a deeper understanding of the psychological processes that underlie human thought and behavior. Specifically, the company is interested in exploring the intricate mechanisms that govern the generation of thoughts and the cognitive processes that shape our perceptions, beliefs, and actions.
      </p>
      <h2 className='name_2'>
        Generative AI Research:
      </h2>
      <div>
        <img className='image_2' src="https://i.ibb.co/swGLTRn/generative-1.jpg" alt='background'/>
    </div>
      <p className='h4tag_2'>
      Working on developing a minimal parameter model for generative AI, as we are aware of the impressive language capabilities of these models. To generate high-quality language outputs, we are exploring questions such as: What is the minimum amount of training data required to train a language model? Is there a minimum threshold for the number of parameters that can be used to achieve optimal language performance?
      </p>
    </div>
  )
}

