import React from 'react'
import "./style.css";


export default function Home() {
  return (
      <body>
        <h1 className='mainhead_1'>
         Cnyda Dynamics Research & Development
        </h1>
        <p className='header_1'>
        Cnyda dynamics is a research & development company which primarily focuses on advanced mathematics and technology research including Artificial Intelligence, Machine Learning and Big Data Analytics. The founder's driving force behind starting the company was a deep-seated passion to address the complex problems that plague our society, and to use technology, mathematics and other sciences as a tool to find creative solutions to these challenges. 
        The current political and economic environment presents a significant challenge for nations to distribute their wealth and opportunities fairly among their citizens. This issue requires careful consideration and innovative solutions to ensure that all members of society have access to the resources they need to live a dignified life.  
        In order to aid the governments across the globe in achieving the above objectives, our company firmly believes that the success of any government or private organization, regardless of the capatalist or communist economic system in that country, hinges on four essential factors:
        </p>
        <div >
            <h2 className='name1'>Factor 1: Access to high quality healthcare with low/zero cost</h2>
           <img className='image_1' src="https://i.ibb.co/p0PB9hF/doctor-1.jpg" alt='background'/>
        </div>
      
          <p className='header_1' >             
             We believe that all the citizens should have access to healthcare options that are not only effective but also financially accessible, so that they can prioritize the well-being of themselves and their families without breaking the bank. 
             The healthcare experience for a common citizen should be on par with that of a wealthy citizen, without any disparities in care 
             The reference to wealthy citizens here is to serve as a benchmark/reference point for assessing the quality of healthcare received by common citizens in that country
          </p>
        
        <div >
           <h2 className='name1'>Factor 2: Rational Oracle/Margdarshi</h2>
           <img className='image_1' src="https://i.ibb.co/4dXnz4C/mentor-1.jpg" alt='background'/>
        </div>
        
          <p className='header_1'>
             Every citizen, regardless of their family's educational heritage, should be granted the chance to learn from and be mentored by individuals with exceptional cognitive abilities or 
            an AI avatar with such brain, thereby cultivating rationality and knowledge from a formative age. The lack of such mentorship is a primary reason for the under-achievement, 
            high crime rate and socio-economic disadvantages experienced by children from lower-income families.
          </p>
       
        <div>
           <h2 className='name1'>Factor 3: Access to high quality job skills with min/zero cost</h2>
           <img className='image_1' src="https://i.ibb.co/DMpbkMV/professor-2.jpg" alt='background'/>
        </div>
        <div>
          <p className='header_1'>
           The emphasis on memory-based examinations in the current educational system often results in a disconnect between education and true knowledge acquisition, hindering the learning process and limiting the potential of students. Governments should strive to offer their citizens not just education, but also the acquisition of marketable skills, so that they may earn a decent living and enjoy the dignity of financial independence.
          </p>
        </div>
        <div >
           <h2 className='name1'>Factor 4: Access to top quality and quick Legalis Muneris</h2>
           <img className='image_1' src="https://i.ibb.co/RBKC1yD/legal-1.jpg" alt='background'/>
        </div>
        <div>
        <p className='header_1'>
          Any citizen should be able to protect their property and self-respect through legal means, regardless of their socioeconomic status. The legal system should be accessible and affordable for all, ensuring that justice is not limited to those with the means to afford it.
        </p>
       
        {/* <div >
           <h2 className='name1'>Access to high quality healthcare with low/zero cost:</h2>
           <img className='image_1' src="https://i.ibb.co/c63T48M/head04.jpg" alt='background'/> 
        </div> */}
        </div>

        <p className='header_1'>
        The company is actively developing innovative Artificial Intelligence systems that closely mimic human thought processes, offering exceptional reliability at a fraction of the cost typically associated with existing AI technologies. This approach makes it possible for common citizens to benefit from these advanced solutions. To accomplish the above objectives, the company aims to collaborate with governments and corporations to facilitate technology-driven policy changes.
        </p>
      </body>

  ); 
}