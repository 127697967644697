import { BrowserRouter, Routes, Route} from "react-router-dom";
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import Research from './components/pages/Research'
import Products from './components/pages/Products'
import EvLine from "./components/pages/EvLine";
import Patents from "./components/pages/Patent"
import Footer from './components/footer';


export default function App() {
  return (
  <div>
    <BrowserRouter>
      <EvLine/>
      <Navbar/>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/Research' element={<Research />} />
          <Route exact path='/Products' element={<Products />} />
          <Route exact path='/Patents' element={<Patents />} />
        </Routes>
        <Footer/>
  </BrowserRouter>

  </div>
       
  );
}
