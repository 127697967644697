import React from 'react'
import "./Products.css";


export default function CareersHiring() {
  return (
    <div className='text'>
     
       <h1 className='mainhead_3'>
         Cnyda Dynamics Research & Development
        </h1>
      <p className='header_3'>
      The company not only engages in research activities, but also strives to transform theoretical discoveries into practical products through its development team. Our current focus is on creating a range of products across the following areas of expertise:
      </p>
      <h2 className='name_pro'>
      AI Patent for Stock Market Regulators:
      </h2>
      
      <div >
         <img className='image_3' src="https://i.ibb.co/djbTPmM/regulator-2.jpg" alt='background'/>
      </div>
     <p className='header_3'>
     We have developed a groundbreaking Cognitive Brain AI architecture specifically designed for stock market regulators worldwide. This innovative solution is geared towards monitoring, detecting, and gathering scientific evidence on nefarious activities such as insider trading, pump-and-dump schemes, and other forms of market manipulation. This innovative product is poised to revolutionize the detection of insider trading and other fraudulent activities, empowering regulatory authorities with a powerful tool in their ongoing efforts to combat market manipulation and protect investors. We are engaging with regulatory authorities and are prepared to showcase our cutting-edge technology through a live demonstration.
     </p>
     <h2 className='name_pro'>
     AI Doctor avatar:
      </h2>
     <div>
         <img className='image_3' src="https://i.ibb.co/DLbT4zq/doctor-4.webp" alt='background'/>
      </div>
     <p className='header_3'>
     We are developing an innovative 'Doctor AI avatar' by harnessing our cutting-edge research on human brain and cognition to address one of the most pressing healthcare challenges of our time. This AI Doctor avatar will be trained across 18 specialties and will be compared to expert doctors in its training phase; this platform will enable nursing staff and primary physicians to tap into the collective expertise of all specialists in one comprehensive resource, fostering significant synergies during physical examinations, diagnoses, and treatments for patients. 
     </p>
    
      <p className='header_3'>
       •	Other exciting products are forthcoming in our pipeline
      </p>
      </div>
  );
}
